<template>
  <div class="Address">
    <H5Navheader :name="'地址详情'" />
    <div class="main-body">
      <div class="info-detail-card">
        <div class="title">地址详情</div>
        <div class="content">
          <div class="address">
            <div class="label">地址</div>
            <div class="value">
              {{baseInfo.addr}}
            </div>
          </div>
          <div class="info-item">
            <div class="label">总发送</div>
            <div class="value">{{baseInfo.fromCount}}</div>
          </div>
          <div class="info-item">
            <div class="label">总接收</div>
            <div class="value">{{baseInfo.toCount}}</div>
          </div>
          <div class="info-item">
            <div class="label">文化数据数量</div>
            <div class="value">{{baseInfo.assetCount}}</div>
          </div>
        </div>
      </div>

      <div class="table-wrap-pc" style="margin-top: 90px">
        <div class="title">最新信息</div>
        <el-table :data="tableData" style="width: 100%" v-loading="loading">
          <el-table-column prop="createTime" label="时间" width="180">
          </el-table-column>
          <el-table-column prop="transactionHash" label="交易哈希" width="400">
            <template slot-scope="scope">
              <router-link :to="`/hexDetail?keyword=${scope.row.transactionHash}`">{{scope.row.transactionHash}}</router-link>
            </template>
          </el-table-column>
          <el-table-column prop="blockHash" label="区块哈希" width="400">
          </el-table-column>
          <el-table-column prop="count" label="数量"> </el-table-column>
        </el-table>
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="params.pageNum"
          :limit.sync="params.pageSize"
          @pagination="getData"
        />
      </div>

      <div class="list-wrap-h5">
        <div class="title">最新信息</div>
        <div class="card-item" v-for="item in tableData" :key="item.id">
          <div class="hex">
            <div class="text">
              <!-- {{item.transactionHash}} -->
              <template>
                <router-link :to="`/hexDetail?keyword=${item.transactionHash}`">{{item.transactionHash}}</router-link>
              </template>
            </div>
            <div class="title">交易哈希</div>
          </div>
          <!-- <div class="hex">
            <div class="text">{{item.blockHash}}</div>
            <div class="title">区块哈希</div>
          </div> -->
          <div class="info">
            <div class="info-item">
              <div class="value">{{item.count}}</div>
              <div class="title">交易数量</div>
            </div>
            <div class="info-item">
              <div class="value">{{item.createTime}}</div>
              <div class="title">时间</div>
            </div>
          </div>
        </div>
        <div v-if="loading" class="h5-loading-wrap"><van-loading type="spinner" size="24px" color='#fff'>加载中...</van-loading></div>
      </div>
    </div>
    <!-- <H5Tab :index="1" /> -->
  </div>
</template>
<script>
// import H5Tab from "@/components/H5Tab";
import H5Navheader from "@/components/H5Navheader";
import { addrDetail } from "@/api/index.js";

export default {
  name: "Address",
  components: {
    // H5Tab,
    H5Navheader,
  },
  created() {
    this.keyword = this.$route.query.keyword
    // console.log(this.keyword)
    this.getData()
    window.addEventListener('scroll', this.scrollBottom);
  },
  mounted() {},
  destroyed() {
    window.removeEventListener('scroll', this.scrollBottom) //页面离开后销毁监听事件
  },
  data() {
    return {
      keyword: '',
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 100,
      tableData: [],
      baseInfo: {
        addr: '...',
        assetCount: '...',
        fromCount: '...',
        toCount: '...',
      },
      loading: true,
      pages: 0,
    };
  },
  methods: {
    getData() {
      this.loading = true
      addrDetail({addr:this.keyword,...this.params}).then(res => {
        this.baseInfo = {
          addr: res.data.addr,
          assetCount: res.data.assetCount || 0,
          fromCount: res.data.fromCount || 0,
          toCount: res.data.toCount || 0,
        },
        this.tableData = res.data.addrList.rows
        this.loading = false
        this.pages = res.data.addrList.pages
        this.total = res.data.addrList.total
      })
    },
    /** h5 触底加载 */
    scrollBottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight + 150 >= scrollHeight && !this.loading && this.params.pageNum < this.pages) {
        if (this.isMobile()) {
          // alert('移动端到底了')
          this.loading = true
          this.params.pageNum ++
          addrDetail({addr:this.keyword,...this.params}).then((res) => {
            this.tableData = this.tableData.concat(res.data.addrList.rows)
            // console.log(this.tableData)
            // this.total = res.total
            this.loading = false
          })
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.Address {
  .info-detail-card {
    background-color: #20223A;
    border: 1px solid #F5670E;
    @media screen and (min-width: 1200px) {
      padding: 30px;
      > .title {
        font-size: 20px;
        padding-bottom: 19px;
        font-weight: bold;
      }
      .content {
        display: flex;
        align-items: center;

        .address {
          background: linear-gradient(90deg, #F5670E, #FFCCAC);
          border-radius: 13px;
          padding: 14px 32px 14px 12px;
          display: flex;
          align-items: center;
          .label {
            font-size: 14px;
            color: #18184c;
            font-weight: bold;
            padding-right: 54px;
          }
          .value {
            color: #18184c;
            font-size: 12px;
          }
        }
        .info-item {
          margin-left: 60px;
          .label {
            font-size: 14px;
            color: #ababff;
            padding-bottom: 12px;
          }
          .value {
            font-size: 12px;
          }
        }
      }
    }
    @media screen and (max-width: 1200px) {
      word-break: break-all;
      padding: 0.3rem;
      margin: 0 0.24rem;
      > .title {
        font-size: 0.32rem;
        font-weight: bold;
        padding-bottom: 0.2rem;
      }
      .content {
        display: flex;
        flex-wrap: wrap;
        .address {
          padding: 0.2rem 0.3rem;
          background: linear-gradient(90deg, #F5670E, #FFCCAC);
          border-radius: 0.18rem;
          .label {
            display: none;
          }
          .value {
            color: #18184c;
            font-size: 0.2rem;
          }
        }
        .info-item {
          .label {
            padding-top: 0.3rem;
            padding-bottom: 0.10rem;
            color: #ABABFF;
            font-size: 0.2rem;
            padding-right: 1.48rem;
          }
          &:last-child {
            .label {
              padding-right: 0;
            }
          }
          .value {
            font-size: 0.24rem;
          }
        }
      }
    }
  }
  .list-wrap-h5 {
    padding: 0.3rem 0;
    >.title {
      display: inline-block;
      font-size: 0.28rem;
      padding: 0.29rem 0.93rem;
      border-bottom: 1px solid #F5670E;
    }
    .card-item {
      padding: 0.3rem;
      margin: 0.3rem 0.24rem;
      &:nth-child(even) {
        background: #252C4A;
      }
      &:nth-child(odd) {
        background: #1B2337;
      }
      .hex {
        .text {
          word-break: break-all;
          font-size: 0.2rem;
          color: #F5670E;
        }
        .title {
          font-size: 0.2rem;
          color: #ABABFF;
          padding: 0.14rem 0 0.38rem 0;
        }
      }
      .info {
        display: flex;
        align-items: center;
        .info-item {
          &:nth-child(1) {
            .title {
              padding-right: 0.89rem;
            }
          }
          .value {
            font-size: 0.24rem;
          }
          .title {
            font-size: 0.2rem;
            color: #ABABFF;
            padding-top: 0.1rem;
          }
        }
      }
    }
    @media screen and (min-width: 1200px) {
      display: none;
    }
  }
}
</style>
